import { useSelector } from "react-redux";
import "./style.css";
import { Link, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";

function FooterXl() {
  const languageData = useSelector((state) => state.language.languageData);

  return (
    <footer className="xl mt-lg-5 ">
      <div className="container">
        <div className="card shadow-sm border-radius-1 p-3 border-radius-lg-3 p-lg-4 p-xxxl-6">
          <div className="d-flex align-items-start">
            <div>
              <NavLink to="/">
                <img
                  src="/alpho_teknoloji_logo.svg"
                  className="footer-logo"
                  alt="Alpho Teknoloji Logo"
                />
              </NavLink>
            </div>
            <div className="d-flex gap-5 gap-xl-10 gap-xxl-15 ms-xl-10 ms-xxl-30 flex-wrap flex-grow-1 ms-7">
              <div className="d-flex flex-column">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/products/"
                >
                  {languageData[1035]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/popular-products/"
                >
                  {languageData[136]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/new-products/"
                >
                  {languageData[137]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/discounted-products/"
                >
                  {languageData[138]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/AlphoBloges/"
                >
                  AlphoBlog
                </NavLink>
              </div>
              <div className="d-flex flex-column">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/faq/"
                >
                  {languageData[122]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/online-shopping-help/"
                >
                  {languageData[123]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/shipping-returns/"
                >
                  {languageData[124]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/technical-support/"
                >
                  {languageData[125]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/feedback/"
                >
                  {languageData[126]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/career/"
                >
                  {languageData[127]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header " : ""
                  }
                  to="/about-us/"
                >
                  {languageData[1106]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header " : ""
                  }
                  to="/contact-us/"
                >
                  {languageData[1107]}
                </NavLink>
              </div>
              <div className="d-flex flex-column">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/get-a-quotation/"
                >
                  {languageData[1108]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header " : ""
                  }
                  to="/the-benefits-of-alpho-club/"
                >
                  {languageData[1109]}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                  to="/join-now/"
                >
                  {languageData[1104]}
                </NavLink>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-end">
            <div
              className="d-flex align-items-center justify-content-center icons icons-social-footer"
              style={{ gap: 10 }}
            >
              <a target="_blank" href="https://www.facebook.com/profile.php?id=61556386682787">
                <Icon icon="bxl:facebook" color="var(green-dark" />
              </a>
              <a target="_blank" href="    https://www.instagram.com/alphoteknoloji/ ">
                <Icon icon="iconoir:instagram" color="var(green-dark" />
              </a>
              <a target="_blank" href="https://twitter.com/alphoteknoloji">
                <Icon
                  icon="simple-icons:x"
                  color="var(green-dark"
                  className="twitter"
                />
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/alphoteknoloji/?viewAsMember=true">

                <Icon icon="ri:linkedin-fill" color="var(green-dark" />
              </a>
            </div>
            <div
  className="d-flex flex-column align-items-end justify-content-center icons icons-social-footer mt-50"
  style={{ gap: 10, marginTop: "50%" }}
>
                <a target="_blank" href="https://www.trendyol.com/sr?mid=412540&pi=10">
               <img src="/assets/img/magazalar/trendyol_logo.png" alt="Trendyol Logo" />
              </a>
                <a target="_blank" href="https://www.hepsiburada.com/magaza/alphotek">
               <img src="/assets/img/magazalar/hepsiburada_logo.png" alt="Hepsiburada Logo" />
              </a>
                <a target="_blank" href="#">
               <img src="/assets/img/magazalar/pazarama_logo_.png" alt="Hepsiburada Logo" />
              </a>
                <a target="_blank" href="#">
               <img src="/assets/img/magazalar/n11_logo.svg" alt="Hepsiburada Logo" />
              </a>

            </div>

</div>

          </div>
          <div className="hr mt-5 mb-3" />
          <div className="row footer-bottom flex-nowrap">
            <div className="col-4">{languageData[1110]}</div>
            <div className="col-4 d-flex gap-2 flex-wrap justify-content-center">
              <Link to="/privacy-policy/">{languageData[1111]}</Link>
              <Link to="/terms-and-conditions/">{languageData[65]}</Link>
              <Link to="/clarification-text/">{languageData[215]}</Link>
            </div>
            <div className="col-4 text-end">{languageData[1115]}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterXl;
