import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartProducts: [],
};

const cartSlice = createSlice({
  name: "carts",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { product_id, quantity, comb_price_id } = action.payload;
      if (
        !state.cartProducts.some(
          (item) =>
            item.product_id === product_id &&
            item.comb_price_id === comb_price_id
        )
      ) {
        state.cartProducts.push({ product_id, quantity, comb_price_id });
      }
    },
    updateCart: (state, action) => {
      const { product_id, quantity, comb_price_id } = action.payload;

      const updatedCartProducts = state.cartProducts.map((item) => {
        if (
          item.product_id === product_id &&
          item.comb_price_id === comb_price_id
        ) {
          return {
            ...item,
            quantity,
          };
        }
        return item;
      });

      if (
        !state.cartProducts.some(
          (item) =>
            item.product_id === product_id &&
            item.comb_price_id === comb_price_id
        )
      ) {
        updatedCartProducts.push({ product_id, quantity, comb_price_id });
      }

      state.cartProducts = updatedCartProducts;
    },

    deleteFromCart: (state, action) => {
      const { product_id, comb_price_id } = action.payload;
      state.cartProducts = state.cartProducts.filter(
        (item) =>
          !(
            item.product_id === product_id &&
            item.comb_price_id === comb_price_id
          )
      );
    },
    deleteAllCart: (state) => {
      state.cartProducts = [];
    },
    setCart: (state, action) => {
      state.cartProducts = action.payload;
    },
  },
});

export const { addToCart, updateCart, deleteFromCart, deleteAllCart, setCart } =
  cartSlice.actions;
export default cartSlice.reducer;
