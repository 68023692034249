import { useRef } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../../hooks/user/useLogut";
import { setModal } from "../../../redux/modalSlice";
import { AnimatePresence, motion } from "framer-motion";
import useCloseModal from "../../../hooks/useCloseModal";
import useGetUserData from "../../../hooks/user/useGetUserData";

function Account() {
  const { languageData } = useSelector((state) => state.language);
  const modal = useSelector((state) => state.modal);
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const logout = useLogout();

  useCloseModal(modalRef, "account");

  const { user } = useGetUserData();

  return (
    <AnimatePresence>
      {modal === "account" && (
        <motion.aside
          layoutId="account"
          initial={{
            transform: "translate(100%, 0)",
          }}
          animate={{
            transform: "translate(-10px, 0)",
          }}
          exit={{
            transform: "translate(100%, 0)",
          }}
          ref={modalRef}
          className="modal-header-area-account-menu border-radius-2 border-radius-lg-4 overflow-hidden shadow"
        >
          <div className="modal-header-account-menu p-3 p-lg-7 ">
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">
                <div className="text-end">
                  <Icon
                    icon="material-symbols-light:close"
                    height={30}
                    onClick={() => dispatch(setModal(null))}
                  />
                </div>
                <div className="text-center account-img mt-5">
                  <Icon icon="iconamoon:profile-circle-fill" height={75} />
                  <div className="account-header-namesurname font-size-32">
                    {user?.first_name} {user?.last_name}
                  </div>
                  <div className="font-size-18 account-header-email">
                    {user?.email}
                  </div>
                </div>
                <nav className="vertical-menu account-dropdown font-size-24 mt-10 flex-grow-1">
                  <ul>
                    {/*  <li>
                      <Icon icon="mdi:bell-notification-outline" height={25} />
                      <Link to="/" className="dropdown-menu-link ms-2">
                        {languageData[129]}
                      </Link>
                    </li> */}
                    <li className="mt-4">
                      <Icon icon="mdi:cube-outline" height={25} />
                      <Link to="/orders/" className="dropdown-menu-link ms-2">
                        {languageData[130]}
                      </Link>
                    </li>
                    <li className="mt-4">
                      <Icon icon="mdi:recent" height={25} />
                      <Link
                        to="/recently-viewed/"
                        className="dropdown-menu-link ms-2"
                      >
                        {languageData[131]}
                      </Link>
                    </li>
                    <li className="mt-4">
                      <Icon icon="mdi:settings-outline" height={25} />
                      <Link
                        to="/account-settings/"
                        className="dropdown-menu-link ms-2"
                      >
                        {languageData[132]}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <button
                className="button-hover mt-3 w-100 shadow-none"
                onClick={logout}
              >
                {languageData[133]}
              </button>
            </div>
          </div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
}

export default Account;
