import Cookies from "js-cookie";
import { JWT_STORAGE } from "../constants/env";
import { decryptText } from "./crypto";
import { jwtDecode } from "jwt-decode";

export default function getUserId() {
  const encryptedToken = Cookies.get(JWT_STORAGE);
  if (!encryptedToken) {
    return;
  }

  const decryptedToken = decryptText(encryptedToken);

  if (!decryptedToken) {
    return;
  }

  const user = jwtDecode(decryptedToken);
  return user.user_id;
}
