import CryptoJS from "crypto-js";
import { ENCRYPT_KEY } from "../constants/env";

export const encryptText = (text) => {
  return CryptoJS.AES.encrypt(text, ENCRYPT_KEY).toString();
};

export const decryptText = (encryptedText) => {
  return CryptoJS.AES.decrypt(encryptedText, ENCRYPT_KEY).toString(
    CryptoJS.enc.Utf8
  );
};
