import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCTS_ARRAY, WISHLIST } from "../../../constants/endpoints";
import Api from "../../../services/axios.service";
import { setWishlist } from "../../../redux/whishlistSlice";
import getUserId from "../../../helpers/getUserId";

export default function useGetWishList() {
  const api = new Api();
  const { wishListProducts } = useSelector((state) => state.wishList);
  const user_id = getUserId();

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);

    try {
      if (wishListProducts.length === 0) setProducts([]);
      else {
        const res = await axios.post(PRODUCTS_ARRAY, {
          products: wishListProducts,
        });
        setProducts(res.data);
      }
    } catch (err) {
      setError("Failed to fetch product prices.");
    } finally {
      setLoading(false);
    }
  };

  const fetchWishlist = async () => {
    setError(null);
    if (user_id) {
      try {
        const data = await api.get(WISHLIST);
        dispatch(setWishlist(data));
      } catch (err) {
        setError("Failed to fetch cart data.");
      }
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [wishListProducts]);

  useEffect(() => {
    fetchWishlist();
  }, [user_id]);

  return { products, loading, error };
}
