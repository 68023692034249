import { useEffect } from "react";
import { useLocation } from "react-router";

function useScrollTop() {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [location]);
}

export default useScrollTop;
