import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-input-range/lib/css/index.css";
import "swiper/css";
import "swiper/css/navigation";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "./assets/css/style.css";
import store from "./redux/store";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypalInitialOptions } from "./data/paypalInitialOptions";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PayPalScriptProvider options={paypalInitialOptions}>
    <Provider store={store}>
      <App />
    </Provider>
  </PayPalScriptProvider>
);
