// Recent Post Card
import { memo } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import RecentPostCard from "../../components/RecentPostCard";
import RecentPostCardPlaceholder from "../../components/RecentPostCard/RecentPostCardPlaceholder";
import "./style.css";
import useGetData from "../../services/crud/useGetData";
import { BLOG } from "../../constants/endpoints";

function RecentPostsComponent() {
  const { languageData } = useSelector((state) => state.language);
  const { data, isLoading, error } = useGetData({ key: BLOG });

  if (isLoading) {
    return (
      <section className="recent-posts">
        <div className="container">
          <div className="content-title">{languageData[1001]}</div>
          <br />
          <div className="loading">{languageData[2090]}</div>
          <Swiper
            spaceBetween={35}
            modules={[FreeMode]}
            slidesPerView="auto"
            className="recent-posts-swiper pb-3"
          >
            <SwiperSlide>
              <RecentPostCardPlaceholder />
            </SwiperSlide>
            <SwiperSlide>
              <RecentPostCardPlaceholder />
            </SwiperSlide>
            <SwiperSlide>
              <RecentPostCardPlaceholder />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    );
  }

  if (!data || data.length === 0) return null;

  return (
    <section className="recent-posts">
      <div className="container">
        <div className="content-title">{languageData[1001]}</div>
        <br />
        {error ? (
          <div className="text-danger text-center">{languageData[2089]}</div>
        ) : (
          <Swiper
            spaceBetween={35}
            modules={[FreeMode]}
            slidesPerView="auto"
            className="recent-posts-swiper pb-3"
          >
            {data.map((p) => (
              <SwiperSlide key={p._id}>
                <RecentPostCard postData={p} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  );
}

const RecentPosts = memo(RecentPostsComponent);

export default RecentPosts;
