import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CART, PRODUCTS_ARRAY_PRICE } from "../../../constants/endpoints";
import Api from "../../../services/axios.service";
import { setCart } from "../../../redux/cartSlice";
import getUserId from "../../../helpers/getUserId";

export default function useGetCarts() {
  const { cartProducts } = useSelector((state) => state.carts);
  const dispatch = useDispatch();
  const [productsWithQuantity, setProductsWithQuantity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userId = getUserId();

  const fetchProductsWithQuantity = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.post(PRODUCTS_ARRAY_PRICE, {
        products: cartProducts,
      });

      if (userId) {
        setProductsWithQuantity(
          res.data.map((product) => ({
            product,
            quantity:
              cartProducts.find(
                (cp) =>
                  cp.comb_price_id === product.comb_prices[0].comb_price_id &&
                  cp.product_id === product.product_id
              ).quantity || 1,
          }))
        );
      } else {
        setProductsWithQuantity(
          res.data.map((product, index) => ({
            product,
            quantity: cartProducts[index].quantity || 1,
          }))
        );
      }
    } catch (err) {
      console.log(err);
      setError("Failed to fetch product prices.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCart = async () => {
    const api = new Api();
    setError(null);
    if (userId) {
      try {
        const data = await api.get(CART);
        dispatch(setCart(data));
      } catch (err) {
        setError("Failed to fetch cart data.");
      }
    }
  };

  useEffect(() => {
    fetchProductsWithQuantity();
  }, [cartProducts]);

  useEffect(() => {
    fetchCart();
  }, [userId]);

  return { productsWithQuantity, loading, error };
}
