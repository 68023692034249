import axios from "axios";
import getToken from "../helpers/getToken";
import { BASE_URL } from "../constants/env";

export default class Api {
  constructor(contentType = "application/json", baseURL = BASE_URL) {
    this.baseURL = baseURL;
    this.axiosObject = axios.create({
      baseURL: this.baseURL,
      headers: {
        "Content-Type": contentType,
      },
    });

    this.axiosObject.interceptors.request.use(
      (config) => {
        const token = getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get = async (url) => {
    const res = await this.axiosObject.get(url);
    return res.data;
  };

  post = async (url, { arg }) => {
    const res = await this.axiosObject.post(url, arg);
    return res.data;
  };

  patch = async (url, { arg }) => {
    const res = await this.axiosObject.patch(url, arg);
    return res.data;
  };

  delete = async (url) => {
    const res = await this.axiosObject.delete(url);
    return res.data;
  };
}
