// END POINTS

import { BASE_URL } from "./env";

// Products -- Session Check Et
export const ALL_PRODUCTS = "web/products";
export const FEATURED_PRODUCTS = "web/featured/product";
export const BEST_SELLING = "web/products-best-selling";
export const NEW_PRODUCTS = "web/products-newest";
export const DISCOUNTED_PRODUCTS = "web/products-discounted";
export const SIMILAR_PRODUCTS = "web/products-similar";
export const POPULAR_PRODUCTS = "web/products-popular";
export const SEARCH_PRODUCTS = "web/search/all";
export const PRODUCT_DETAIL = "web/find/product";
export const PRODUCTS_ARRAY = BASE_URL + "web/product/find-array";
export const PRODUCTS_ARRAY_PRICE = BASE_URL + "web/product/find-array-price";

// Categories -- Session Check Et
export const CATEGORIES = "web/category-all";
export const CATEGORY_DESCRIPTION = "web/category-description";

// User
export const USER = "users";
export const USER_LOGIN = BASE_URL + "users/login";
export const USER_ADDRESS = "users/address";
export const ORDERS_END_POINT = "order";
export const NOTIFICATIONS = "notifications";

export const RESET_PASSWORD_ONE = "users/reset-password-one";
export const RESET_PASSWORD_TWO = "users/reset-password-two";

// Wishlist
export const WISHLIST = "wishlist";
export const ADD_WISHLIST = "wishlist/add";
export const DELETE_WISHLIST = "wishlist/delete";

// Cart
export const CART = "cart";
export const ADD_CART = "cart/add";
export const DELETE_CART = "cart/delete";

// Recently Viewed
export const RECENTLY_VIEWED = "recently-viewed";

// Form Mailleri -- Session Check Et
export const FORM_MAILS = "form-mails";

// PAYMENT
export const PAYMENT = "payment";
export const PAYMENT_IYZICO = "payment/iyzipay-checkout";
export const PAYMENT_PAYPAL = "payment/paypal-checkout";
export const PAYMENT_PAYPAL_CAPTURE = "payment/paypal-complete";
export const PAYMENT_STRIPE = "payment/stripe-checkout";
export const PAYMENT_STRIPE_COMPLETED = "payment/stripe-complete";
export const PAYMENT_WIRE_TRANSFER_TR = "payment/wire-transfer-tr";
export const PAYMENT_WIRE_TRANSFER = "payment/wire-transfer";

// Currency
export const CURRENCIES = "currency/";

// Coupon
export const COUPON_CHECK = "coupon/check";

// Session Check Et
export const LANGUAGES = "language";
export const PAGE_SEO = "page-seos";
export const CARGO_PRICES = "cargo-prices";
export const ATR_FEE = "atr-fee";
export const ABOUT_US = "about-us";
export const BLOG = "blogs";
export const FAQ = "faq";
export const ONLINE_SHOPPING = "online-shopping-help";
export const SHIPPING_RETURNS = "shipping-and-returns";
