import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setModal } from "../redux/modalSlice";

function useCloseModal(ref, modalName, closeModal) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.classList.contains(modalName) &&
        modalName
      ) {
        dispatch(setModal(null));
        if (closeModal) closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, modalName, closeModal, dispatch]);

  useEffect(() => {
    dispatch(setModal(null));
  }, [location.pathname, dispatch]);
}

export default useCloseModal;
