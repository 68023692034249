import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useGetData } from "../services/crud";
import { SEARCH_PRODUCTS } from "../constants/endpoints";

function useSearchProduct() {
  const [value, setValue] = useState("");
  const [search] = useDebounce(value, 500);

  const { data, isLoading, error } = useGetData({
    key: search ? `${SEARCH_PRODUCTS}?search=${search}` : null,
  });

  return {
    data,
    isLoading,
    error,
    value,
    setValue,
    search,
  };
}

export default useSearchProduct;
