import { useEffect, useState } from "react";

function useResponsive() {
  const [size, setSize] = useState(
    window.innerWidth > 1199.8
      ? "xl"
      : window.innerWidth > 991.98
      ? "lg"
      : window.innerWidth > 767.98
      ? "md"
      : "sm"
  );

  useEffect(() => {
    const handleResize = () => {
      setSize(
        window.innerWidth > 1199.8
          ? "xl"
          : window.innerWidth > 991.98
          ? "lg"
          : window.innerWidth > 767.98
          ? "md"
          : "sm"
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
}

export default useResponsive;
