import "./style.css";

function GradientBackground() {
  return (
    <>
      <div className="gradient-background gradient-background-right-top"></div>
      <div className="gradient-background gradient-background-left-bottom"></div>
    </>
  );
}

export default GradientBackground;
