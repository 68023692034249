import { USER } from "../../constants/endpoints";
import getUserId from "../../helpers/getUserId";
import { useGetData } from "../../services/crud";

function useGetUserData() {
  const userId = getUserId();

  const { data, isLoading, error, mutate } = useGetData({
    key: userId ? USER : null,
  });

  return {
    isLoading,
    user: data?.[0] || null,
    error,
    mutate,
  };
}

export default useGetUserData;
