import Cookies from "js-cookie";
import { JWT_STORAGE } from "../../constants/env";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setModal } from "../../redux/modalSlice";
import { deleteAllWishlist } from "../../redux/whishlistSlice";
import { deleteAllCart } from "../../redux/cartSlice";

function useLogout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setModal(null));
    dispatch(deleteAllWishlist());
    dispatch(deleteAllCart());

    Cookies.remove(JWT_STORAGE);
    document.body.style.overflow = "auto";
    document.getElementsByTagName("header")[0].classList.remove("active");
    navigate("/");
  };

  return logout;
}

export default useLogout;
