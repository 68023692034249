import "./style.css";
import HeaderXs from "./HeaderXs";
import HeaderXl from "./HeaderXl";
import CartModal from "../../components/Modal/CartModal";
import AccountModal from "../../components/Modal/AccountModal";
import SearchModal from "../../components/Modal/SearchModal";
import useResponsive from "../../hooks/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../redux/modalSlice";
import useGetWishList from "../../hooks/user/wishlist/useGetWishList";

function Header() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const screenSize = useResponsive();
  useGetWishList();

  return (
    <>
      {screenSize === "xl" ? <HeaderXl /> : <HeaderXs />}
      <CartModal />

      {modal === "search" && (
        <div
          className="modal-header-menu"
          onClick={() => dispatch(setModal(null))}
        >
          <div
            className="modal-header-content"
            onClick={(e) => e.stopPropagation()}
          >
            <SearchModal />
          </div>
        </div>
      )}
      <AccountModal />
    </>
  );
}

export default Header;
