import { useState } from "react";
import getUserId from "../../../helpers/getUserId";
import {
  deleteFromCart,
  addToCart,
  updateCart,
} from "../../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../services/axios.service";

import { ADD_CART, CART, DELETE_CART } from "../../../constants/endpoints";
import { toast } from "react-toastify";

export default function useCartToggle({
  product_id,
  comb_price_id,
  from = "product-cart",
}) {
  const { cartProducts } = useSelector((state) => state.carts);
  const { languageData } = useSelector((state) => state.language);

  const isInCart = cartProducts.some(
    (item) =>
      item.product_id === product_id && item.comb_price_id === comb_price_id
  );

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const api = new Api();

  const cartToggle = async (
    product_id,
    quantity = 1,
    comb_price_id,
    type = "update"
  ) => {
    if (isLoading) return;
    const userId = getUserId();

    setIsLoading(true);

    try {
      if (userId) {
        const body = isInCart
          ? {
              product_id,
              comb_price_id,
              quantity,
            }
          : {
              carts: [{ product_id, quantity, comb_price_id }],
            };

        if (from === "product-cart") {
          if (isInCart) {
            await api.post(DELETE_CART, {
              arg: body,
            });
            dispatch(deleteFromCart({ product_id, comb_price_id }));
          } else {
            await api.post(isInCart ? DELETE_CART : ADD_CART, {
              arg: body,
            });
            dispatch(addToCart({ product_id, comb_price_id }));
            toast.success(languageData[1168], {
              toastId: product_id + comb_price_id + quantity,
            });
          }
        } else if (from === "order-cart") {
          if (type === "update") {
            await api.patch(CART, {
              arg: body,
            });
            dispatch(updateCart({ product_id, quantity, comb_price_id }));
            if (isInCart)
              toast.success(languageData[1170], {
                toastId: product_id + comb_price_id + quantity,
              });
          } else {
            await api.post(DELETE_CART, {
              arg: body,
            });
            dispatch(deleteFromCart({ product_id, comb_price_id }));
          }
        } else {
          if (isInCart) {
            await api.patch(CART, {
              arg: body,
            });
            dispatch(updateCart({ product_id, quantity, comb_price_id }));
            toast.success(languageData[1170], {
              toastId: 2,
            });
          } else {
            await api.post(ADD_CART, {
              arg: body,
            });
            dispatch(addToCart({ product_id, quantity, comb_price_id }));
          }
        }
      } else {
        if (from === "product-cart") {
          dispatch(
            isInCart
              ? deleteFromCart({ product_id, comb_price_id })
              : addToCart({ product_id, quantity, comb_price_id })
          );
        } else if (from === "order-cart") {
          if (type === "update") {
            dispatch(updateCart({ product_id, quantity, comb_price_id }));
            toast.success(languageData[1170], {
              toastId: 2,
            });
          } else {
            dispatch(deleteFromCart({ product_id, comb_price_id }));
          }
        } else {
          if (isInCart)
            dispatch(updateCart({ product_id, quantity, comb_price_id }));
          else dispatch(addToCart({ product_id, quantity, comb_price_id }));
        }
      }
    } catch (isError) {
      toast.error(languageData[1159], {
        toastId: 10,
      });
      console.log(isError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isInCart,
    isLoading,
    cartToggle,
  };
}
