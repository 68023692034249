import Api from "../axios.service";
import useSWR from "swr";

function useGetData({ key, options }) {
  const api = new Api();

  return useSWR(key, api.get, {
    shouldRetryOnError: false,
    dedupingInterval: 100000,
    refreshInterval: 100000,
    ...options,
  });
}

export default useGetData;
