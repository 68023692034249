import GradientBackground from "../GradientBackground";
import Spinner from "../Spinner";
import "./style.css";

function Loader() {
  return (
    <div className="loader">
      <GradientBackground />
      <Spinner isLarge />
    </div>
  );
}

export default Loader;
