import useSWRMutation from "swr/mutation";
import Api from "../../services/axios.service";

function useUpdateData({ key, idText = "_id", url, revalidate = false }) {
  const api = new Api();

  return useSWRMutation(key, async (key, data) => api.patch(url, data), {
    populateCache: (res, cached) => {
      return cached.map((item) =>
        item[idText] === res[idText] ? { ...item, ...res } : item
      );
    },
    revalidate,
  });
}

export default useUpdateData;
