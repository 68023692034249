import { Link } from "react-router-dom";

const ProductSearchBarCard = ({ product }) => (
  <Link
    to={`/products/${product.product_id}/`}
    className="product-search-bar__card text-wrap font-size-22 border-bottom"
  >
    {product.product_name}
  </Link>
);

function ProductSearchBarList({ products, isError, errorText }) {
  return (
    <div className="bg-white border-radius-1 border-radius-lg-2 card product-search-bar__list mt-1 mt-lg-3">
      <div className="d-flex flex-column gap-3 p-3 p-lg-5">
        {isError ? (
          <div className="text-danger">{errorText}</div>
        ) : (
          products.map((p) => (
            <ProductSearchBarCard key={p.product_id} product={p} />
          ))
        )}
      </div>
    </div>
  );
}

export default ProductSearchBarList;
