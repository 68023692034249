import { memo } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import "./style.css";
import { setModal } from "../../redux/modalSlice";
import getUserId from "../../helpers/getUserId";
import useGetUserData from "../../hooks/user/useGetUserData";

function NavigatorComponent() {
  const { languageData } = useSelector((state) => state.language);
  const user_id = getUserId();

  const dispatch = useDispatch();

  const isProductsActive = useMatch("/products");
  const isHomeActive = useMatch("/");
  const isFavoritesActive = useMatch("/favorites");

  const { user } = useGetUserData();

  return (
    <div className="navigator d-xl-none">
      <div className="container h-100">
        <div className="d-flex gap-5 h-100 align-items-center justify-content-center">
          <NavLink to="/products/" className={isProductsActive ? "active" : ""}>
            <Icon
              width={30}
              icon={
                isProductsActive ? "mingcute:grid-fill" : "mingcute:grid-line"
              }
            />
            <div className="navigator-title mt-1">{languageData[1035]}</div>
          </NavLink>
          <button
            onClick={() => dispatch(setModal("search"))}
            className="search"
          >
            <Icon width={30} icon="tabler:search" className="search" />
            <div className="navigator-title mt-1 search">
              {languageData[139]}
            </div>
          </button>
          <NavLink to="/" className={isHomeActive ? "active" : ""}>
            <Icon
              width={30}
              icon={
                isHomeActive
                  ? "material-symbols:home"
                  : "material-symbols:home-outline"
              }
            />
            <div className="navigator-title mt-1">{languageData[2006]}</div>
          </NavLink>
          <NavLink
            to="/favorites/"
            className={isFavoritesActive ? "active" : ""}
          >
            <Icon
              width={30}
              icon={
                isFavoritesActive
                  ? "typcn:star-full-outline"
                  : "typcn:star-outline"
              }
            />
            <div className="navigator-title mt-1">{languageData[1116]}</div>
          </NavLink>
          {user_id ? (
            <button
              onClick={() => dispatch(setModal("account"))}
              className="account"
            >
              <div className="account">
                {user?.first_name.charAt(0)}
                {user?.last_name.charAt(0)}
              </div>
              <div className="navigator-title mt-1 account">
                {languageData[1117]}
              </div>
            </button>
          ) : (
            <NavLink to="/login/">
              <Icon width={30} icon="ic:outline-login" />
              <div className="navigator-title mt-1">{languageData[140]}</div>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}
const Navigator = memo(NavigatorComponent);

export default Navigator;
