import useSWRMutation from "swr/mutation";
import Api from "../../services/axios.service";

function useAddData({ key }) {
  const api = new Api();

  return useSWRMutation(key, api.post, {
    populateCache: (newItem, current = []) => [...current, newItem],
    revalidate: false,
  });
}

export default useAddData;
