import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Unauthorized() {
  const { languageData } = useSelector((state) => state.language);

  return (
    <main className="mb-5 mb-lg-7 mt-120">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <div className="card border-radius-1 border-radius-lg-3 p-3 p-lg-7">
                <h1 className="font-size-42 font-weight-semi-bold d-b">
                  {languageData[183]}
                </h1>

                <div className="mt-5 mt-lg-10">
                  <div className="font-size-20 text-wrap d-flex gap-1">
                    <div>{languageData[184]}</div>
                  </div>
                </div>
                <Link
                  to="/login/"
                  className="button-hover mt-3 w-100 shadow-none"
                  type="submit"
                >
                  {languageData[140]}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Unauthorized;
