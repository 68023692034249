import useSWRMutation from "swr/mutation";
import Api from "../../services/axios.service";

function useDeleteData({ key, idText = "_id", url }) {
  const api = new Api();

  return useSWRMutation(key, () => api.delete(url), {
    populateCache: (res, cached) =>
      cached.filter((item) => item[idText] !== res),
    revalidate: false,
  });
}

export default useDeleteData;
