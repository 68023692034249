import { Link, NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./style.css";
import CompanyModal from "../../../components/Modal/CompanyModal";
import NanografiScienceClubModal from "../../../components/Modal/NanografiScienceClubModal";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../redux/modalSlice";
import ProductSearchBar from "../../../components/ProductSearchBar/ProductSearchBar";
import getUserId from "../../../helpers/getUserId";
import useGetUserData from "../../../hooks/user/useGetUserData";
import TechnologyAreasModal from "../../../components/Modal/TechnologyAreasModal";

function FavCount() {
  const { wishListProducts } = useSelector((state) => state.wishList);

  return (
    <>
      {wishListProducts.length > 0 && (
        <div className="small">{wishListProducts.length}</div>
      )}
    </>
  );
}

export function CartCount() {
  const { cartProducts } = useSelector((state) => state.carts);

  return (
    <>
      {cartProducts.length > 0 && (
        <div className="small">{cartProducts.length}</div>
      )}
    </>
  );
}

function HeaderXl() {
  const user_id = getUserId();

  const { languageData } = useSelector((state) => state.language);
  const { user } = useGetUserData();

  const modal = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  return (
    <>
      <header className={`xl ${modal ? "active" : ""}`}>
        <div className="container h-100 d-flex flex-column pb-1 pt-2">
          <div className="d-flex align-items-center justify-content-between gap-10 flex-grow-1">
            <NavLink to="/">
              <img
                src="/alpho_teknoloji_logo.svg"
                className="header-logo"
                alt="Alpho Teknoloji Logo"
              />
            </NavLink>
            <ProductSearchBar />
            <div className="d-flex align-items-center gap-4 icons">
              <NavLink
                to="/favorites/"
                className="d-flex align-items-center gap-1"
              >
                <Icon icon="typcn:star-outline" className="icon" width={25} />
                <FavCount />
              </NavLink>
              <button
                className="d-flex align-items-center gap-1 cart"
                onClick={() => dispatch(setModal("cart"))}
              >
                <Icon icon="teenyicons:bag-outline" className="icon cart" />
                <CartCount />
              </button>
              {user_id ? (
                <button
                  className="account rounded-circle shadow"
                  onClick={() => dispatch(setModal("account"))}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                >
                  {user?.first_name.charAt(0)}
                  {user?.last_name.charAt(0)}
                </button>
              ) : (
                <div className="d-flex gap-2 align-items-center">
                  <Link
                    to="/login/"
                    className="button-hover px-3 font-size-20 text-nowrap shadow-none"
                  >
                    {languageData[140]}
                  </Link>
                  <Link
                    className="button-hover active px-2 font-size-20 text-nowrap shadow-none"
                    to="/signup/"
                  >
                    {languageData[1154]}
                  </Link>
                </div>
              )}
            </div>
          </div>
          <nav className="nav-menu flex-grow-1 d-flex align-items-center">
            <ul>
              <li>
                <NavLink
                  to="/products/"
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                >
                  {languageData[1035]}
                </NavLink>
              </li>

              <li>
                <button
                  className="d-flex align-items-center company"
                  onClick={() => dispatch(setModal("company"))}
                >
                  {languageData[1102]}
                  <Icon
                    icon="ep:arrow-down-bold"
                    className={`ms-1 rotate-vertical company ${
                      modal === "company" ? "rotate-vertical-active" : ""
                    }`}
                    width={12}
                  />
                </button>
              </li>
              <li>
                <NavLink
                  to="/get-a-quotation/"
                  className={({ isActive }) =>
                    isActive ? "active-header" : ""
                  }
                >
                  {languageData[1108]}
                </NavLink>
              </li>
              <li>
                <button
                  className="d-flex align-items-center science"
                  onClick={() => dispatch(setModal("science"))}
                >
                  {languageData[1109]}
                  <Icon
                    icon="ep:arrow-down-bold"
                    className={`ms-1 rotate-vertical science ${
                      modal === "science" ? "rotate-vertical-active" : ""
                    }`}
                    width={12}
                  />
                </button>
              </li>

              <li>
                <button
                  className="d-flex align-items-center science"
                  onClick={() => dispatch(setModal("technolog-areas"))}
                >
                  {languageData[220]}
                  <Icon
                    icon="ep:arrow-down-bold"
                    className={`ms-1 rotate-vertical science ${
                      modal === "science" ? "rotate-vertical-active" : ""
                    }`}
                    width={12}
                  />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      {modal === "company" && (
        <div
          className="modal-header-menu"
          onClick={() => dispatch(setModal(null))}
        >
          <div
            className="modal-header-content"
            onClick={(e) => e.stopPropagation()}
          >
            <CompanyModal />
          </div>
        </div>
      )}
      {modal === "science" && (
        <div
          className="modal-header-menu"
          onClick={() => dispatch(setModal(null))}
        >
          <div
            className="modal-header-content"
            onClick={(e) => e.stopPropagation()}
          >
            <NanografiScienceClubModal />
          </div>
        </div>
      )}
      {modal === "technolog-areas" && (
        <div
          className="modal-header-menu"
          onClick={() => dispatch(setModal(null))}
        >
          <div
            className="modal-header-content"
            onClick={(e) => e.stopPropagation()}
          >
            <TechnologyAreasModal />
          </div>
        </div>
      )}
    </>
  );
}

export default HeaderXl;
