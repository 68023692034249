import { Icon } from "@iconify/react";
import "./style.css";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { NO_IMAGE_AVAILABLE } from "../../constants/noImageAvailable";
import useCartToggle from "../../hooks/user/cart/useCartToggle";
import { Ring } from "../Spinner";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/env";

function OrderSummaryCardd({ item, errorText }) {
  const cardRef = useRef();
  const { product, quantity } = item;
  const {
    product_id,
    product_code,
    product_name,
    comb_prices,
    product_image_banners,
  } = product;

  const firstComb = comb_prices[0];
  const firstImage = product_image_banners[0];

  const { cartToggle, isLoading, isError } = useCartToggle({
    product_id,
    comb_price_id: firstComb.comb_price_id,
  });

  const handleCardClick = () => {
    if (isLoading) return;
    cardRef.current.classList.toggle("active");
  };

  if (isError) toast.error(errorText);

  return (
    <div
      className={`card order-summary-card shadow-sm border-radius-2 ${
        isLoading ? "active" : ""
      }`}
      ref={cardRef}
      onClick={handleCardClick}
    >
      <article className="order-summary-card__article gap-3 p-3 border-radius-1">
        <div>
          <img
            src={
              BASE_URL + "uploads/" + firstImage?.image_url ||
              NO_IMAGE_AVAILABLE
            }
            alt={firstImage?.image_name}
            className="order-summary-card__img border-radius-1"
            onError={(event) => {
              event.target.src = NO_IMAGE_AVAILABLE;
            }}
          />
        </div>
        <div className="order-summary-card__body d-flex flex-column">
          <div className="flex-grow-1">
            <Link to={`/products/${product_id}/`}>
              <h2 className="font-size-24 font-weight-regular text-wrap">
                {product_name}
              </h2>
            </Link>
            <div className="mt-1 font-size-20">SKU: {product_code}</div>
            <div className="font-size-18 d-flex gap-1">
              <div>
                {firstComb.comb_atts
                  .map((cp) => cp.attribute_value.value_name)
                  .join(" x ")}
              </div>
              <div>
                {firstComb.comb_atts
                  .map((cp) => cp.attribute.attribute_name)
                  .join(" x ")}
              </div>
            </div>
          </div>
          <div className="price mt-2 font-size-24">
            {quantity} X {firstComb.price.toFixed(2)} €
          </div>
        </div>
      </article>
      <div
        className="trash-container d-flex align-items-center "
        onClick={(e) => {
          e.stopPropagation();
          cartToggle(product_id, 1, firstComb.comb_price_id);
        }}
      >
        {isLoading ? (
          <Ring color="var(--white-light)" />
        ) : (
          <Icon icon="ph:trash" width={24} />
        )}
      </div>
    </div>
  );
}

export default OrderSummaryCardd;
