import Footer from "./Footer";
import Header from "./Header";
import RecentPosts from "../sections/RecentPosts";
import useScrollTop from "../hooks/useScrollTop";

function Layout(props) {
  useScrollTop();
  const { children } = props;

  return (
    <>
      <Header />
      {children}
      <RecentPosts />
      <Footer />
    </>
  );
}

export default Layout;
