import { useSelector } from "react-redux";
import "./style.css";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../constants/env";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

function RecentPostCard(props) {
  const { postData } = props;
  const { languageData, languageId } = useSelector((state) => state.language);

  const translation = postData.translations.find(
    (t) => t.languageId === languageId
  );
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="recent-post-card card border-radius-1 border-radius-lg-3"
    >
      <div className="d-flex h-100 align-items-center">
        <div className="card-body">
          <h6 className="recent-post-title">{translation?.title}</h6>
          <br className="d-sm-none" />
          <div>
            <Link
              to={`/AlphoBlog/${postData?._id}/`}
              className="button-hover d-sm-none shadow-none"
              tabIndex={-1}
            >
              {languageData[1002]}
            </Link>
          </div>
        </div>
        <div className="recent-post-img">
          <img
            src={`${BASE_URL}uploads/${translation?.img}`}
            alt={translation?.title}
            width="100%"
          />
        </div>
      </div>

      <AnimatePresence>
        {isHovered && (
          <motion.article
            className="d-none d-sm-block recent-post-cover"
            initial={{ transform: "translateY(100%)", opacity: 0 }}
            animate={{ transform: "translateY(0)", opacity: 1 }}
            exit={{ transform: "translateY(100%)", opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="card-body">
              <motion.h6 className="recent-post-title mt-3 text-truncate">
                {translation?.title}
              </motion.h6>
              <motion.p className="recent-post-cover-text mb-3">
                {translation?.summary}
              </motion.p>

              <div>
                <Link
                  to={`/AlphoBlog/${postData?._id}/`}
                  className="button-hover shadow-none"
                >
                  {languageData[1002]}
                </Link>
              </div>
            </div>
          </motion.article>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default RecentPostCard;
