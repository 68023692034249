import "./style.css";
import FooterXl from "./FooterXl";
import FooterXs from "./FooterXs";
import useResponsive from "../../hooks/useResponsive";

function Footer() {
  const screenSize = useResponsive();

  return <>{screenSize === "sm" ? <FooterXs /> : <FooterXl />}</>;
}

export default Footer;
