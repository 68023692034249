import TECHNOLOGY_AREAS from "../../../data/technologyAreas.json";
import { NavLink } from "react-router-dom";

function TechnologyAreasModal() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav className="vertical-menu">
            <ul>
              {TECHNOLOGY_AREAS.map((area) => (
                <li key={area.technology_areas_id}>
                  <NavLink
                    to={`/technology-areas/${area.technology_areas_id}/`}
                    className={({ isActive }) =>
                      isActive
                        ? "active-header dropdown-menu-link font-size-20"
                        : "dropdown-menu-link font-size-20"
                    }
                  >
                    {area.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TechnologyAreasModal;
