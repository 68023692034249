import Cookies from "js-cookie";
import { JWT_STORAGE } from "../constants/env";
import { decryptText } from "./crypto";

export default function getToken() {
  const encryptedToken = Cookies.get(JWT_STORAGE);
  if (!encryptedToken) {
    return;
  }

  const decryptedToken = decryptText(encryptedToken);

  if (!decryptedToken) {
    return;
  }

  return decryptedToken;
}
