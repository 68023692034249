// wishlistSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishListProducts: [],
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action) => {
      const product = action.payload;
      if (
        !state.wishListProducts.some(
          (item) => item.product_id === product.product_id
        )
      ) {
        state.wishListProducts.push(product);
      }
    },
    deleteFromWishlist: (state, action) => {
      const productIdToDelete = action.payload.product_id;
      state.wishListProducts = state.wishListProducts.filter(
        (product) => product.product_id !== productIdToDelete
      );
    },
    deleteAllWishlist: (state) => {
      state.wishListProducts = [];
    },
    setWishlist: (state, action) => {
      state.wishListProducts = action.payload;
    },
  },
});

export const {
  addToWishlist,
  deleteFromWishlist,
  deleteAllWishlist,
  setWishlist,
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
